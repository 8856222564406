@import url("https://fonts.googleapis.com/css2?family=Inter&family=Sen:wght@700&display=swap");

/* .profile {
  display: flex;
  flex-direction: column;
  flex: 4;
  align-items: space-between;
  justify-content: center;
  flex-wrap: wrap;
} */

.profileCard {
  position: relative;
  display: inline-block;
  width: 22vw;
  height: 28vw;
  vertical-align: top;
  /* align the profile cards to the top of the container */
  align-items: center;
  text-align: center;
  margin: 20px 15px 0px 0px;
  padding-top: 20px;
  padding-bottom: 5px;
  border-radius: 10px;
  background-color: #fbf6ea;
  transition: all .2s ease-in-out;
}
.profileContainer {
  margin-top: 40px;
  text-align: center;
}
.profileCard:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}

/* profile card info */
.profileCardInfo {
  padding: 10px;
}

.profileCard img {
  width: 80%;
  aspect-ratio: 1;
  border-radius: 10px;
  object-fit: cover;
}

.profileCard h3 {
  font-family: "Sen", sans-serif;
  font-size: 1.4vw;
  height: 1.3vw;
  margin: 1.3rem 0;
}

.profileCard p {
  font-size: 1vw;
  color: #666;
  font-family: "Inter", sans-serif;
  height: 2.5vw;
}

.profile-social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 50%;
  padding-top: 0;
  transition: all .01s ease-in-out;
}

.profile-social-icons a:hover {
  transform: scale(1.2);
}

a.social {
  font-size: 1.3vw;
  margin: 0 0.9vw;
  color: #232536;
}

@media screen and (max-width: 900px) and (min-width: 750px) {

  /*900px screen*/
  .profileCard {
    width: 30.5vw;
    height: 30vw;
  }

  .profileCardInfo {
    padding: 0px;
  }

  .profileCard img {
    width: 19vw;
    height: 19vw;
  }

  .profileCard h3 {
    font-size: 1.83vw;
    height: 0.7vw;
    margin-top: 0.6rem;
  }

  .profileCard p {
    font-size: 1.35vw;
    height: 3vw;
  }

  .social-icons {
    margin-left: 3.05vw;
  }

  a.social {
    font-size: 1.8vw;
    margin: 0 1.5vw;
  }
}

@media screen and (max-width: 750px) {

  /*750px*/
  .profileCard {
    width: 73.5vw;
    height: auto;
    margin: 20px 0px 0px 0px;
  }

  .profileCardInfo {
    padding: 0px;
  }

  .profileCard img {
    width: 44vw;
    height: 44vw;
  }

  .profileCard h3 {
    font-size: 4.58vw;
    height: 3vw;
    margin-top: 0.4rem;
  }

  .profileCard p {
    font-size: 3.4vw;
    height: 8vw;
  }

  .social-icons {
    margin-left: 8.3vw;
  }

  /* .profile-social-icons {
    padding-top:0px;
    margin-bottom: 10px;
  } */

  a.social {
    font-size: 4vw;
    margin: 0 3.74vw;
  }
}

@media only screen and (max-width:450px) {
  .profile-social-icons {
    margin-top: 0px;
    padding-top: 15px;
  }
  .profileCard p {
    padding-top: 0px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 900px) {
  .profileCard {
    width: 24vw;
    height: 25vw;
    margin: 20px 15px -0.5vw 0;
  }

  .profileCard img {
    width: 14vw;
    height: 14vw;
  }

  .profileCard h3 {
    font-size: 1.45vw;
    height: 0.7vw;
  }

  .profileCard p {
    font-size: 1.1vw;
    height: 2.7vw;
  }

  .social-icons {
    margin-left: 3.2vw;
  }

  a.social {
    font-size: 1.4vw;
    margin: 0 1.03vw;
  }
}