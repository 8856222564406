body {
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #FFE9C3; 
}
 
/* Handle */
body::-webkit-scrollbar-thumb {
  background: #000; 
  border-radius: 10px;
}

*::selection {
  background-color: #FFE9C3;
  color: #000;
}