@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');


.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 35px;
    height: 100vh;
    margin: 32px 0 0 0;
}

.carousel {
    height: 100%;
    width: 100%;

}

.slide {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    gap: 4vh;
    background-repeat: no-repeat;
    /* background-position: right 50% bottom 30%; */
    background-position: center center;
    background-size: cover;
}

.slide .hallDescription {
    padding-bottom: calc(24vh + 72px);
    padding-left: 6vw;
}

.slide .hallName {
    padding-left: 6vw;
}

:global(.swiper-button-prev),
:global(.swiper-button-next),
:global(.swiper-button-next)::after,
:global(.swiper-button-prev)::after {
    /* width: 5vw !important; */
    font-size: 4vmin !important;
    color: #FFD050 !important;
}

:global(.swiper-button-next) {
    right: 4vmin !important;
}

:global(.swiper-button-prev) {
    left: 4vmin !important;
}

.slide1 {
    background-image: linear-gradient(6deg, rgba(0, 0, 0, 0.6), rgba(15, 14, 14, 0.82)), url("/public/header.png");
}

.slide2 {
    background-image: linear-gradient(6deg, rgba(0, 0, 0, 0.6), rgba(15, 14, 14, 0.82)), url("/public/header2.jpg");
}

.slide3 {
    background-image: linear-gradient(6deg, rgba(0, 0, 0, 0.6), rgba(15, 14, 14, 0.82)), url("https://images.unsplash.com/photo-1476610182048-b716b8518aae?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1259&q=80");
}

.slide4 {
    background-image: linear-gradient(6deg, rgba(0, 0, 0, 0.6), rgba(15, 14, 14, 0.82)), url("https://images.unsplash.com/photo-1432958576632-8a39f6b97dc7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80");
}

.hallName {
    width: 60vw;
    margin-top: 104px;
    padding-bottom: 10px;
    font-family: 'Sen';
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    line-height: 0.9;
    letter-spacing: -2px;
    color: white;
}

.hallDescription {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    width: 55vw;
    color: #FFFFFF;
    fill: #FFFFFF;
}

.headerbutton {
    position: absolute;
    bottom: 20vh;
    z-index: 10;
    padding-left: 6vw;
}

.headerReadMore {
    padding: 12px 16px;
    flex-direction: row;
    text-align: center;
    font-family: 'Sen';
    font-size: 1.1rem;
    background: #FFD050;
    color: black;
    border: none;
    text-decoration: none;
    transition: all .1s ease-in-out;
    border-radius: 8px;
}

.headerReadMore:hover {
    cursor: pointer;
    transform: scale(1.05);
}

@media (max-width:1200px) {
    .hallName {
        font-size: 4rem;
        padding-top: 0px;
    }

    .hallDescription {
        width: 50vw;
    }

    .header {
        height: 90vh;
        margin-top: 60px;
        /* padding-bottom: 100px; */
    }
}

@media (max-width: 900px) and (min-width: 600px) {
    .headerbutton {
        bottom: 25vh;
    }

    .slide .hallDescription {
        padding-bottom: calc(20vh + 72px);
    }

    .slide .hallName,
    .slide .hallDescription,
    .headerbutton {
        padding-left: 9vw;
    }

    .slide .headerbutton {
        margin-bottom: -10vh;
    }

    .swiper-button-next {
        right: 3vmin !important;
    }

    .swiper-button-prev {
        left: 3vmin !important;
    }
}

@media (max-width:660px) {
    .hallName {
        font-size: 3rem;
        width: 88vw;
    }

    .hallDescription {
        width: 80vw;
    }

    .header {
        margin-top: 10px;
    }

    .slide .hallDescription {
        padding-bottom: 20vh;
    }

    .slide .headerbutton {
        margin-bottom: -10vh;
    }
}

@media (max-width:500px) {
    .hallName {
        font-size: 2.5rem;
        line-height: 1.2;
        color: white;
    }

    .header {
        min-height: 85vh;
    }

    .hallDescription {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #FFFFFF;
        width: 80vw;
        color: #FFFFFF;
        fill: #FFFFFF;
    }

    .headerReadMore {
        padding: 12px 16px;
        flex-direction: row;
        text-align: center;
        font-family: 'Sen';
        font-size: 1rem;
        background: #FFD050;
        margin-top: 20px;
        border: none;
    }

    .swiper-button-next,
    .swiper-button-next::after {
        display: none;
    }

    .swiper-button-prev,
    .swiper-button-prev::after {
        display: none;
    }

    .slide .hallName,
    .slide .hallDescription,
    .headerbutton {
        padding-left: 8vw;
    }
}

@media (max-width:380px) {
    .hallName {
        font-size: 1.8rem;
    }

    .hallDescription {
        font-size: 14px;
    }

    .header {
        margin-top: 0px;
    }

    .slide .hallDescription {
        padding-bottom: 16vh;
    }

    .slide .headerbutton {
        margin-bottom: -14vh;
    }

    .header {
        max-height: 60vh;
    }
}