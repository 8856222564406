@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Hebrew:wght@700&family=Inter:wght@700&family=Manrope:wght@500&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.gallerypage {
    overflow: hidden;
}

.boxHeader {
    display: flex;
    align-items: center;
    background-color: #fcfcfc;
    padding: 7px 10px;
    font-family: 'IBM Plex Sans Hebrew', sans-serif;
    font-size: 26px;
    color: #232536;
    border-radius: 10px;
    text-align: center;
}


.yearbox:hover span {
    transition: 0.3s;
    color: #fed051;
}

.yearbox .icon {
    width: 30px;
    color: #fcfcfc;
    margin-right: 5px;
}

.yearbox span {
    font-family: 'Manrope', sans-serif;
    color: #fcfcfc;
    font-size: 18px;
}

.leftbox .active span {
    color: #fed051;
    font-weight: 700;
}

.boxHeader{
    position: relative;
    justify-content: center;
}

.backevent{
    position: absolute;
    left: 12px;
    margin-right: 10px;
    font-size: 30px;
    transition: 0.3s;
    cursor: pointer;
}

.rightbox.active {
    display: none;
}

.rightbox {
    padding: 20px;
    padding-left: 0;
    padding-bottom: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.event {
    margin: 10px;
    background-size: cover;
    background-position: center center;
    height: calc(50vh - 72px);
    border-radius: 8px;
    position: relative;

    display: flex;
    align-items: center;

    background-repeat: no-repeat;
    background-position: center;

    cursor: pointer;
    transition: 0.3s;
}

.event:hover {
    background-color: rgba(0, 0, 0, 0.497);
}

.event img {

    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.event span {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    position: absolute;
    display: block;
    font-size: 24px;
    color: white;
    z-index: 10;
    left: 0;
    right: 0;
    text-align: center;
    transition: 0.3s;
}

.event:hover span {
    font-size: 27px;
}

.dropdown {
    display: none;
}

.mobileyears .yearbox {
    height: 0;
    margin: 0;
    padding: 0;
    background-color: #232536;
}


.eventtitle{
    display: block;
    margin: 12px;
    font-family: 'IBM Plex Sans Hebrew', sans-serif;
    font-size: 26px;
    color: #232536;
    border-radius: 10px;
    text-align: center;
}

.backevent{
    position: static;
}

.yearbox {
    display: none;
}

.yearbox.active {
    display: block;
    margin: auto;
    padding: 12px 20px;
}

.yearbox.active .dropdown {
    display: inline;
    padding: 0px 3px;
    color: #fed051;
}

.leftbox {
    background-color: #222436;
    margin-top: 75px;
    border-radius: 12px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    margin-right: 10px;
    margin-left: 10px;
    width: calc(100% - 20px);
    z-index: 20;
}

.leftbox.active {
    border-bottom-right-radius: 0;
}

.rightbox {
    gap: 20px;
    padding: 10px;
    /* padding-top: 20px; */
    padding-bottom: 20px;
    overflow-x: hidden;
    border-radius: 10px;
}

.event {
    margin: 0;
    height: calc(50vh - 110px);
}

.mobileyears.active {
    display: grid;
    position: absolute;
    grid-template-columns: 1fr 1fr;
    background-color: #232536;
    z-index: 100;
    border-radius: 0 0 20px 20px;
    right: 0;
    margin-right: 10px;
}

.mobileyears.active .yearbox {
    height: 38px;
    margin: 10px;
    padding: 10px;
    background-color: #232536;
}

.mobileyears .yearbox {
    display: block;
}

.gallery {
    margin-left: 0px;
    padding: 10px;
    padding-bottom: 20px;
    overflow-x: hidden;
}

a{
    text-decoration: none;
}

@media screen and (max-width: 1000px){
    .rightbox {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 600px) {
    .rightbox {
        grid-template-columns: 1fr;
    }
    .leftbox {
        margin-top: 67px;
    }
}

.loader{
    padding-top: 20px;
    text-align: center;
    display: block;
    width: 60px;
    font-size: 1.5rem;
    color: gray;
    margin: 40px auto;
}
.loadtext{
    font-size: 16px !important;
}

label {
    text-transform: none !important;
    font: inherit;
}

.noloader{
    padding-top: 20px;
    text-align: center;
    position: absolute;
    width: 100%;
    font-size: 1.5rem;
    color: gray;
    margin-left: -10px;
}

.loadtext {
    font-size: 24px;
    display: block;
    font-family: "Sen";
    text-align: center;
    margin-top: 20px;
}
