@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@100;400&family=Sen:wght@400;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.about_design {
  display: flex;
  margin-bottom: 0;
  display: flex;
  height: 20px;
}

.about_design * {
  padding: 0.5%;
  margin: 0;
}

.about_design .d1 {
  flex-grow: 1;
  background: #ffffff;
}

.about_design .d2 {
  flex-grow: 4;
  background: #ffd050;
}

.about_design .d3 {
  flex-grow: 1;
  background: #592ea9;
}

.aboutUs {
  background-color: #f4f0f8;
  height: 2%;
  margin: 5%;
}

.aboutUs_main {
  margin: 5%;
}

.aboutUs h2 {
  font-size: 2rem;
  text-transform: uppercase;
  text-align: start;
  margin-top: 0;
  margin: 1rem;
  color: #232536;
  font-family: "Sen";
}

.aboutUs_content {
  display: flex;
}

.aboutUs_content>div {
  margin: 1rem;
  margin-top: 0;
  flex: 1;
}

.aboutUs_content h3 {
  font-family: "Sen";
  font-size: 24px;
  padding-bottom: 10px;
  line-height: 30px;
  font-weight: 600;
  margin-top: 1.5rem;
}

.aboutUs_content p {
  padding-top: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #6D6E76;
}

.aboutUs_moto p {
  font-size: 1.2rem;
  font-family: "Sen";
  font-weight: bold;
  text-align: end;
  margin: 1.5rem;
  padding-bottom: 2rem;
  padding-top: 1rem;
  text-transform: uppercase;
}

.aboutUs_more .gb_btn {
  margin: 1rem;
}

.aboutUs_left {
  padding-top: 12px;
}

.aboutUs_right p {
  padding-top: 20px;
}

@media screen and (max-width: 700px) {
  .aboutUs_content {
    flex-direction: column;
  }

  .aboutUs_left {
    padding-top: 0;
  }

  .aboutUs_left .music_room {
    padding-top: 25px;
  }

  @media  screen and (max-width:360px){
    .aboutUs h2 {
      font-size: 1.5rem;
    }
    .aboutUs h3 {
      font-size: 1.3rem;
    }
    .aboutUs_content p {
      font-size: 1rem;
    }
  }
}