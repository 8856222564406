@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@100;400&family=Sen:wght@400;700;800&display=swap");

* {
  box-sizing: border-box;
}

.gallery-box {
  display: flex;
  padding-top: 3vw;
  width: 100%;
  border-radius: 5px;
  justify-content: center;
  align-items: flex-start;
}

.gallery-left {
  padding: 0 20px 20px 20px;
}

.gallery-right {
  padding: 0 20px 20px 20px;
}

.gallery-box h2 {
  font-family: "Sen";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -2px;
  color: #232536;
}

.gallery-left .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 12px;
  padding: 24px;
  opacity: 0.9;
  height: auto;
  border: 1px solid #6d6e76;
  border-radius: 5px;
}

.gallery-right .content {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 3.6em);
}
.gallery-left .content img {
  border: 1px solid #04092c;
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.gallery-left .content h4 {
  font-family: "Sen";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-block-end: 0.5em;
  margin-block-start: 0em;
  color: #232536;
}

.gallery-left .gallery-text {
  width: 100%;
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin: 24px 0;
  color: #6d6e76;
}

.gallery-box button {
  display: flex;
  padding: 0.8rem 2rem;
  gap: 12px;
  border: 0;
  justify-content: center;
  align-items: center;
  background: #ffd050;
  cursor: pointer;
}

.illu-text {
  /* ILLUMINATION l 2016 */
  height: 20px;
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #4c4c4c;
  margin: 4px;
}


.gallery-right .content img {
  width: 100%;
  margin: 6px 0;
  border-radius: 5px;
}

.right-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right-heading>.gallery-text {
  cursor: pointer;
  font-family: "Inter Tight";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #592ea9;
}

@media screen and (max-width: 900px) {
  .gallery-box h2 {
    font-size: 30px;
  }
  .gallery-right>.content>img {
    height: auto;
  }

  .gallery-left .content h4 {
    font-size: 30px;
    line-height: 28px;
  }

  .gallery-left .gallery-text {
    font-size: 20px;
    line-height: 20px;
  }

  label {
    font-size: 10px;
  }
  .gallery-left .content {
    border: 0px solid red;
  }

  .gallery-box button>.gallery-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .gallery-text-button {
    margin-left: 200%;
    height: 4vh;
  font-size: 2vh;
  width:20vw;
  }
}
.gallery-below .gallery-text{
  font-size: 16px;
}
@media screen and (max-width: 400px) {
  .gallery-text-button {
    margin-left: 40%;
    height: 5vh;
  width:50vw;
  }
  .gallery-below .gallery-text{
    font-size: 16px;
    line-height: 20px;
  }
  .gallery-box button>.gallery-text {
    font-size: 1.8vh;
  }
  .gallery-left .content {
    border: 0px solid red;
    padding: 5px;
  }
  .gallery-left .content h4{
    font-size: 30px;
  }
}