
.footer {
  background-color: #1a1a1a;
  color: #ffffff;
  font-family: 'Sen', sans-serif;
  padding: 40px 0 0 ;
  margin-top: 20px;
}

.footerContent {
  padding-bottom: 20px;
}


.logoSection {
  display: flex;
  align-items: center;
  gap: 15px;
}

.logoImage {
  width: 60px;
  height: auto;
}

.logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
}

.description {
  margin: 0;
  font-size: 0.9rem;
  color: #b3b3b3;
}

/* Contact Us */
.contactUs h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.contactUs p {
  margin: 5px 0;
  font-size: 0.9rem;
  color: #b3b3b3;
}

.contactUs strong {
  color: #ffffff;
}


.socialLinks h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #ffffff;
}

.socialLinks p {
  margin: 0 0 10px;
  font-size: 0.9rem;
  color: #b3b3b3;
}

.icons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.socialLink {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #ffffff;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.socialLink:hover .icon{
  color: #00bcd4;
}

.icon {
  font-size: 1.2rem;
  color: #ffffff;
}

/* Footer Bottom */
.footerBottom {
  background-color: #121212;
  text-align: center;
  padding: 15px 0;
  font-size: 0.8rem;
  color: #b3b3b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .logoSection {
    flex-direction: column;
    align-items: flex-start;
  }

  .logoImage {
    width: 50px;
  }

  .icons {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
  }

  .socialLink {
    flex-direction: row;
  }
}