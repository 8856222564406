@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap');

.testimonials {
    background: #FBF6EA;
    margin: 5%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.testimonials .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 20px;
    letter-spacing: 3px;
    padding: 16px 52px;
    text-transform: uppercase;
    text-align: left;
    color: #232536;
}

.testimonials .content-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonials .leftSide {
    width: 30%;
}

.testimonials .rightSide {
    width: 50%;
}

.testimonials .nextHead {
    font-family: 'Sen';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: -2px;
    color: #232536;
    margin-top: 0;
    margin-bottom: 5%;
}

.testimonials .leftSide .text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #232536;
}

.testimonials .border {
    opacity: 0.4;
    border: 0.05em solid #6D6E76;
    margin: 6%;
    height: 400px;
}

.testimonials .rightSide {
    margin: 0px;
}

.testimonials .rightSide .heading {
    font-family: 'Sen';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    padding-right: 15%;
    color: #232536;
    text-transform: none;
    letter-spacing: 0;
    word-spacing: 2px;
    min-height: 40vh;
    height: 40vh;
    width: 100%;
    overflow-y: auto;
    min-height: 200px;
}

.testimonials .heading::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.testimonials .heading::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #FFE9C3;
}

/* Handle */
.testimonials .heading::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px;
}

.testimonials .rightLower .person .name {
    font-family: 'Sen';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #232536;
}

.testimonials .rightLower {
    margin: 24px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.testimonials .rightLower .person .company {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #6D6E76;
}


.testimonials .rightLower .person {
    width: 75%;
    padding: 10px;
}

.testimonials .rightLower .buttons {
    display: flex;
}

.testimonials .rightLower .buttons:hover {
    cursor: pointer;
}

.testimonials .rightLower .buttons .back {
    margin-right: 16px;
    object-fit: cover;
    width: 62px;
}

.testimonials .person .name,
.testimonials .heading,
.testimonials .person .company {
    animation-name: fade;
    animation-duration: 1.5s;
}

.testimonials .padBtn {
    padding-top: 4vh;
    padding-bottom: 10px;
}

.testimonials .addbutton {
    margin-top: 12px;
    width: 'auto';
    height: 45px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    padding: 12px 16px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #fff;
    background-color: #000;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.testimonials .addbutton.acceptBtn:hover {
    background-color: #000;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.4);
    color: #fff;
    transform: translateY(-7px);
}

.testimonials .form {
    width: 300px;
    padding: 10px 40px 10px 30px;
}

.testimonials label {
    text-transform: none;
    font-size: 14px;
    letter-spacing: 0.03em;
    font-weight: bold;
    margin-top: 10px;
}

.testimonials input,
.testimonials textarea {
    border: 1px solid #ccc;
    color: #333;
    width: calc(100% - 30px);
}

.testimonials input,
.testimonials textarea {
    border-radius: 4px;
    padding: 8px 15px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 300;
    margin-top: 10px;
    width: 300px;
}

@media only screen and (max-width: 440px) {

    input,
    .testimonials textarea {
        width: 90%;
    }

    .testimonials {
        flex-direction: column;
        align-items: flex-start;
    }

    .testimonials .content-box {
        flex-direction: column;
    }

    .testimonials .border {
        opacity: 0.4;
        border: 0.05em solid #6D6E76;
        margin: 6%;
        width: 200px;
        height: 1px;
    }

    .testimonials .title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 2px;
        padding-top: 24px;
    }

}

@keyframes fade {
    from {
        opacity: .05
    }

    to {
        opacity: 1
    }
}

@media only screen and (max-width: 886px) {
    .testimonials .pad-btn {
        padding-top: 14vh;
    }
}

@media only screen and (max-width: 640px) {
    .testimonials {
        display: block;
    }

    .testimonials .pad-btn {
        padding-top: 18px;
        padding-bottom: 10px;
    }

    .testimonials .leftSide {
        width: 90%;
        padding: 5%;
        padding-top: 8%;
    }

    .testimonials .rightSide {
        width: 75%;
        padding-right: 0;
    }

    .testimonials .rightSide .heading {
        width: 75vw;
    }

    .testimonials .rightLower {
        margin-left: 0;
        margin-top: 20px;
    }

    .testimonials .rightLower .buttons {
        margin-left: 0;
        padding-left: 0;
        margin-top: 0;
    }

    .testimonials .rightLower .buttons .back {
        width: 30px;
        height: 30px;
    }

    .testimonials .rightLower .buttons .next {
        width: 30px;
        height: 30px;
    }
}

@media only screen and (max-width: 320px) {
    .testimonials .title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 2px;
        padding-top: 24px;
    }

    .testimonials .rightSide .heading {
        font-weight: 600;
        font-size: 1rem;
        line-height: 24px;
        word-spacing: 2px;
        min-height: 32vh;
        height: 32vh;
    }

    .testimonials .rightLower .person .name {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }

    .testimonials .rightLower .person .company {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
    }

    .testimonials .leftSide .nextHead {
        font-size: 1.6rem;
        width: 70vw;
    }

    .testimonials .leftSide .text {
        font-size: 0.8rem;
        width: 70vw;
        text-align: start;
    }

    .testimonials {
        padding: 0px;
    }
}