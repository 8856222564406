.logo-container {
    width: 30px;
    transition: all .05s ease-in-out;
}

.logo-container:hover {
    transform: scale(1.15);
}

.menu-items {
    display: flex;
    gap: 8px;
}

.menu-item {
    color: white;
    transition: all .05s ease-in-out;
}

.menu-item:hover {
    color: black !important;
    background-color: #FFD050 !important;
    transform: scale(1.1);
}

.menu-item-drawer {
    color: white;
    text-align: center !important;
    border-bottom: 1px solid grey;
}

.menu-item-drawer:hover {
    color: black !important;
    background-color: #FFD050 !important;
}